<template>
	<div>
		<div class="table-responsive">
			<table class="table table-hover mb-2" :class="{'condensed': condensed}" data-cy="Tabela">
				<thead>
					<th v-if="selectItem">
						<div style="display: flex">
							<input
								type="checkbox"
								v-model="isChecked"
								style="margin-right: 8px;"
								@change="selectAll(isChecked)"
							/>
							<div v-if="isChecked" style="display: flex !important">
								<div
									v-if="permissaoPayment && globalAcoes"
									class="icon-acao icon-payment-bg icon-container"
									title="Baixa"
									@click="paymentItem('All')"
								>
									<CreditCardIcon size="24" alt="Pagamento"/>
								</div>
								<div
									v-if="permissaoCancel && globalAcoes"
									class="icon-acao icon-cancel-bg icon-container"
									title="Cancelar"
									@click="cancelItem('All')"
								>
									<XCircleIcon size="24" alt="Cancelar"/>
								</div>
								<div
									v-if="permissaoDelete && globalAcoes"
									class="icon-acao icon-delete-bg icon-container"
									title="Apagar"
									@click="deleteItem('All')"
								>
									<Trash2Icon size="24" alt="Apagar"/>
								</div>
							</div>
						</div>
					</th>
				</thead>
				<tbody v-if="loading">
					<tr v-for="i in 3" :key="i">
						<td class="p-1" v-for="j in colunas.length + 1" :key="j">
							<tb-skeleton shape="rect" style="background-color: #dcdcdc; height: 2rem; width:100%" theme="opacity"></tb-skeleton>
						</td>
					</tr>
				</tbody>
				<tbody v-else-if="linhas.length === 0">
					<tr>
						<td scope="row" class="text-center" :colspan="colunas.length + 1">{{errMsg || '(Vazio)'}}</td>
					</tr>
				</tbody>
				<tbody v-else>
					<tr style="display: flex; justify-content: center; justify-items: center; align-items: center;" v-for="(item, idx) in linhas" :key="idx"
					>
						<td v-if="showSelectItem" style="width: 16px; border-style: none; margin-left: -5px">
							<input
								type="checkbox"
								v-model="item.selected"
								@change="verifySelectedItens()"
								:disabled="!(selectItem && !item.notItemActions && !item.notItemSelect)"
							>
						</td>
						<td v-if="acoes" style="width: 30px; border-style: none; margin-top: -6px">
							<div class="container-acoes">
								<div
									v-if="permissaoPayment && !item.notItemActions && !item.isCanceled"
									class="icon-acao icon-payment-bg icon-container"
									title="Baixa"
									@click="paymentItem(item)"
								>
									<CreditCardIcon size="20" alt="Pagamento"/>
								</div>
								<div
									v-if="permissaoEdit && !item.notItemActions && !item.isCanceled"
									class="icon-acao icon-edit-bg icon-container"
									title="Editar"
									@click="editItem(item)"
								>
									<Edit3Icon size="20" alt="Editar"/>
								</div>
								<div
									v-if="permissaoCancel && !item.notItemActions && !item.isCanceled"
									class="icon-acao icon-cancel-bg icon-container"
									title="Cancelar"
									@click="cancelItem(item)"
								>
									<XCircleIcon size="20" alt="Cancelar"/>
								</div>
								<div
									v-if="permissaoDelete && !item.notItemActions && !item.isCanceled"
									class="icon-acao icon-delete-bg icon-container"
									title="Apagar"
									@click="deleteItem(item)"
								>
									<Trash2Icon size="20" alt="Apagar"/>
								</div>
								<div
									v-if="permissaoRecover && item.isCanceled"
									class="icon-acao icon-recover-bg icon-container"
									title="Recuperar"
									@click="recoverItem(item)"
								>
									<CheckCircleIcon size="24" alt="Recuperar"/>
								</div>
							</div>
						</td>
						<td class="font" v-bind:class="!showSelectItem && !acoes ? 'mlm' : ''">
							<b-row v-if="!item.hideLine1 && (colsDataCard['0'].visible || colsDataCard['1'].visible)" no-gutters style="padding-top: 5px;" class="pb-0 mb-0">
								<b-col class="pr-0" v-if="colsDataCard['0'].visible" :cols="colsDataCard['0'].cols">
									<label class="font-label">
										{{ colsDataCard['0'].label }}
									</label>
									<p :class="`font-data ${colsDataCard['0'].textAlign}`">
										{{ (item.cols[colsDataCard['0'].idx] || item.cols[colsDataCard['0'].idx] === 0) ? item.cols[colsDataCard['0'].idx].value || item.cols[colsDataCard['0'].idx] : '' }}
									</p>
								</b-col>
								<b-col class="pl-1" v-if="colsDataCard['1'].visible" :cols="colsDataCard['1'].cols">
									<label class="font-label">
										{{ colsDataCard['1'].label }}
									</label>
									<p :class="`font-data ${colsDataCard['1'].textAlign}`">
										{{ (item.cols[colsDataCard['1'].idx] || item.cols[colsDataCard['1'].idx] === 0) ? item.cols[colsDataCard['1'].idx].value || item.cols[colsDataCard['1'].idx] : '' }}
									</p>
								</b-col>
							</b-row>
							<b-row v-if="!item.hideLine2 && (colsDataCard['2'].visible || colsDataCard['3'].visible || colsDataCard['4'].visible || colsDataCard['5'].visible)" no-gutters class="pt-0 pb-0 mtm-5 mbm-5">
								<b-col class="pr-0" v-if="colsDataCard['2'].visible" :cols="colsDataCard['2'].cols">
									<label class="font-label">
										{{ colsDataCard['2'].label }}
									</label>
									<p :class="`font-data ${colsDataCard['2'].textAlign}`">
										{{ (item.cols[colsDataCard['2'].idx] || item.cols[colsDataCard['2'].idx] === 0) ? item.cols[colsDataCard['2'].idx].value || item.cols[colsDataCard['2'].idx] : '' }}
									</p>
								</b-col>
								<b-col class="pl-1 pr-0" v-if="colsDataCard['3'].visible" :cols="colsDataCard['3'].cols">
									<label class="font-label">
										{{ colsDataCard['3'].label }}
									</label>
									<p :class="`font-data ${colsDataCard['3'].textAlign}`">
										{{ (item.cols[colsDataCard['3'].idx] || item.cols[colsDataCard['3'].idx] === 0) ? item.cols[colsDataCard['3'].idx].value || item.cols[colsDataCard['3'].idx] : '' }}
									</p>
								</b-col>
								<b-col class="pl-1 pr-0" v-if="colsDataCard['4'].visible" :cols="colsDataCard['4'].cols">
									<label class="font-label">
										{{ colsDataCard['4'].label }}
									</label>
									<p :class="`font-data ${colsDataCard['4'].textAlign}`">
										{{ (item.cols[colsDataCard['4'].idx] || item.cols[colsDataCard['4'].idx] === 0) ? item.cols[colsDataCard['4'].idx].value || item.cols[colsDataCard['4'].idx] : '' }}
									</p>
								</b-col>
								<b-col class="pl-1" v-if="colsDataCard['5'].visible" :cols="colsDataCard['5'].cols">
									<label class="font-label">
										{{ colsDataCard['5'].label }}
									</label>
									<p :class="`font-data ${colsDataCard['5'].textAlign}`">
										{{ (item.cols[colsDataCard['5'].idx] || item.cols[colsDataCard['5'].idx] === 0) ? item.cols[colsDataCard['5'].idx].value || item.cols[colsDataCard['5'].idx] : '' }}
									</p>
								</b-col>
							</b-row>
							<b-row v-if="!item.hideLine3 && (colsDataCard['6'].visible || colsDataCard['7'].visible)" no-gutters class="pt-0">
								<b-col v-if="colsDataCard['6'].visible" :cols="colsDataCard['6'].cols" class="pr-0">
									<label class="font-label">
										{{ colsDataCard['6'].label }}
									</label>
									<p :class="`font-data ${colsDataCard['6'].textAlign}`">
										{{ (item.cols[colsDataCard['6'].idx] || item.cols[colsDataCard['6'].idx] === 0) ? item.cols[colsDataCard['6'].idx].value || item.cols[colsDataCard['6'].idx] : '' }}
									</p>
								</b-col>
								<b-col v-if="colsDataCard['7'].visible" :cols="colsDataCard['7'].cols" class="pl-1">
									<label class="font-label">
										{{ colsDataCard['7'].label }}
									</label>
									<p :class="`font-data ${colsDataCard['7'].textAlign}`">
										{{ (item.cols[colsDataCard['7'].idx] || item.cols[colsDataCard['7'].idx] === 0) ? item.cols[colsDataCard['7'].idx].value || item.cols[colsDataCard['7'].idx] : '' }}
									</p>
								</b-col>
							</b-row>
						</td>
						<td v-if="details && !item.noDetails" style="width: 30px; border-style: none;">
							<div
								class="icon-acao icon-details"
								title="Detalhes"
								@click="detailsItem(item)"
							>
								<FileTextIcon size="24" alt="Pagamento"/>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
	export default {
		name: "CardTransaction",
		component: {
		},

		props: {
			colunas: {
				type: Array,
				required: true
			},
			linhas: {
				type: Array,
				required: true
			},
			errMsg: {
				type: String,
				default: ""
			},
			loading: {
				type: Boolean,
				default: false
			},
			condensed: {
				type: Boolean,
				default: false
			},
			acoes: {
				type: Boolean,
				default: false
			},
			permissaoEdit: {
				type: Boolean,
				default: false
			},
			permissaoCancel: {
				type: Boolean,
				default: false
			},
			permissaoDelete: {
				type: Boolean,
				default: false
			},
			permissaoPayment: {
				type: Boolean,
				default: false
			},
			permissaoRecover: {
				type: Boolean,
				default: false
			},
			selectItem: {
				type: Boolean,
				default: false
			},
			globalAcoes: {
				type: Boolean,
				default: false
			},
			colsDataCard: {
				type: Object,
				required: true
			},
			details: {
				type: Boolean,
				default: false
			},
			showSelectItem: {
				type: Boolean,
				default: true
			}
		},
		data () {
			return {
				isChecked: false
			};
		},

		methods: {
			verifySelectedItens () {
				this.isChecked = this.linhas.some(item => item.selected === true);
				this.$emit("clickCheck", this.isChecked);
			},
			editItem (item) {
				this.$emit("clickEdit", item);
			},
			cancelItem (item) {
				this.$emit("clickCancel", item);
				this.isChecked = false;
			},
			deleteItem (item) {
				this.$emit("clickDelete", item);
				this.isChecked = false;
			},
			paymentItem (item) {
				this.$emit("clickPayment", item);
				this.isChecked = false;
			},
			detailsItem (item) {
				this.$emit("clickDetails", item);
				this.isChecked = false;
			},
			recoverItem (item) {
				this.$emit("clickRecover", item);
				this.isChecked = false;
			},
			selectAll (value) {
				this.linhas.forEach(item => {
					if (!item.notItemSelect)
						item.selected = value;
				});
				this.verifySelectedItens();
			}
		},

		watch: {
			linhas: "verifySelectedItens"
		}
	};


</script>

<style scoped>
	.center {
		text-align: center;
	}

	.right {
		text-align: right;
	}

	.left {
		text-align: left;
	}

	.mbm-5 {
		margin-bottom: -10px !important;
	}

	.mtm-5 {
		margin-top: -10px !important;
	}

	.mlm {
		margin-left: -1rem !important;
	}

	.font {
		display: block;
		font-family: "Roboto Condensed Regular";
		letter-spacing: -0.05em;
		font-size: 14px;
		width: 100%;
		padding-left: 1.5rem;
	}

	.font-label {
		margin-bottom: 0px;
		font-size: 11px;
		color: white;
		background-color: var(--cal-header-color);
		width: 100%;
		padding: 1px 5px;
		border-radius: 3px 3px 0 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.font-data {
		height: 28px;
		margin-top: -.75rem !important;
		padding-top: 6px !important;
		padding-left: 3px;
		padding-right: 3px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		background-color: gainsboro;
		border-radius: 0 0 3px 3px;
	}

	table thead {
		background-color: #f5f6fa;
	}

	table thead th {
		user-select: none;
	}

	table thead th[data-ordenar] {
		cursor: pointer;
		user-select: none;
	}

	table thead th[data-active] {
		color: #007bff;
	}

	table thead th[data-ordenar]::after {
		content: ' ↕';
		color: #66666680;
	}

	table[data-asc="1"] thead th[data-active]::after {
		content: ' ↑';
		color: inherit;
	}

	table[data-asc="-1"] thead th[data-active]::after {
		content: ' ↓';
		color: inherit;
	}

	thead th {
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	[data-editar] {
		width: 8rem;
		text-align: center;
	}

	.container-pesquisa {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.text--black {
		color: black;
	}

	.form-control {
		height: calc(1.5em + 0.75rem + 4px);
	}

	.table-responsive {
		max-height: 60vh;
		white-space: nowrap;
		overflow-y: auto;
		overflow-x: auto;
	}

	.table.condensed td,
	.table.condensed th {
		padding: 0.25rem 0.5rem;
	}

	@media (max-width: 500px) {
		.container-pesquisa.mb-1 {
			flex-direction: column;
			align-items: center;
		}

		.custom-select.ml-1 {
			margin: 0.25rem;
		}
	}

	.linha-alert {
		width: 70%;
		padding: .5rem 0;
		background-color: red;
		color: white;
		font-weight: 600;
	}

	.container-acoes {
		display: block;
		padding: 2px;
	}

	.icon-acao {
		height: 30px;
		width: 28px;
		border-radius: 3px;
		margin-bottom: 3px;
	}

	.icon-delete-bg {
		display: flex;
		justify-content: center;
		background-color: red;
		padding: 2px;
	}

	.icon-cancel-bg {
		display: flex;
		justify-content: center;
		background-color: rgb(231, 134, 6);
		padding: 2px;
	}

	.icon-recover-bg {
		display: flex;
		justify-content: center;
		background-color: var(--cal-header-color);
		padding: 2px;
	}

	.icon-edit-bg {
		display: flex;
		justify-content: center;
		background-color: grey;
		padding: 2px;
	}

	.icon-payment-bg {
		display: flex;
		justify-content: center;
		background-color: green;
		padding: 2px;
	}

	.icon-container {
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		cursor: pointer;
	}

	.icon-details {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		margin-left: -14px;
		color: gray;
	}
</style>
